<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <b-card>
              <b-card-title>
                How would you like your website to look?
              </b-card-title>
              <b-row>
                <b-col lg="12">
                  <div class="theme-label mb-3">First choose your color.</div>
                </b-col>
              </b-row>
              <b-row class="color-pills">
                <b-col class="lg-12">
                  <b-button
                    v-for="(color, index) in themeColors"
                    :key="index"
                    :class="`color-profile-${color.id}`"
                    @click="selectColor(color.id)"
                    >{{ color.placeholder }}</b-button
                  >
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col lg="12">
                  <div class="theme-label">Choose a layout.</div>
                </b-col>
              </b-row>

              <div class="theme-wrapper">
                <b-row v-if="selectedColorId === 0" class="default-group">
                  <b-col v-for="(image, i) in defaultGroup.images" :key="i">
                    <b-button
                      block
                      size="sm"
                      variant="secondary"
                      class="preview-btn"
                      :disabled="true"
                    >
                      Preview <font-awesome-icon :icon="['fas', 'eye']" />
                    </b-button>
                    <b-img
                      thumbnail
                      rounded
                      :src="
                        require(`@/assets/images/admin/thumbnail/${image.src}`)
                      "
                      :class="`theme-thumb`"
                    ></b-img>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col v-for="(image, i) in group.images" :key="i">
                    <b-button
                      block
                      size="sm"
                      variant="secondary"
                      class="preview-btn"
                      @click="onTemplatePreview({ image, i })"
                    >
                      Preview <font-awesome-icon :icon="['fas', 'eye']" />
                    </b-button>
                    <b-img
                      thumbnail
                      rounded
                      :src="
                        require(`@/assets/images/admin/thumbnail/${image.src}`)
                      "
                      :class="`theme-thumb`"
                      @mouseover="customClass($event, 1)"
                      @mouseout="customClass($event, 0)"
                      v-bind:id="`preview-t${i + 1}-${selectedColorId}`"
                      @click="onSelectTemplate($event, { image, i })"
                    ></b-img>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <AdminFooterButton
              @on-save="onFormSubmit"
              @on-skip="onSkip"
              v-bind:hide-back-button="true"
              :hideSkipButton="candidateTemp.profileTheme > 0 ? false : true"
              :saving="saving"
              :invalid="invalid"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <!-- <ModalThemePreview /> -->
    <theme-preview
      :showThemePreview="showThemePreview"
      :theme="selectedTheme"
      @ok="onTemplateSet"
      @hidden="showThemePreview = false"
    ></theme-preview>
  </div>
</template>

<script>
import ThemePreview from "@/components/admin/ModalThemePreview";
import CandidateMixin from "@/mixins/candidate.mixin";

export default {
  name: "WebsiteTheme",
  mixins: [CandidateMixin],
  components: {
    ThemePreview,
  },
  data() {
    return {
      hideSkipButton: true,
      showThemePreview: false,
      selectedTheme: {},
      selectedColorId: 0,
      selectedThemeId: 0,
      themeColors: [
        {
          id: 1,
          placeholder: "Black/Red",
        },
        {
          id: 2,
          placeholder: "Blue",
        },
        {
          id: 3,
          placeholder: "Salmon",
        },
        {
          id: 4,
          placeholder: "Teal",
        },
        {
          id: 5,
          placeholder: "Gray",
        },
        {
          id: 6,
          placeholder: "Purple",
        },
      ],
      themeGroup: [
        {
          id: 1,
          colorId: 1,
          details: {
            alt: "Theme 1 Teal",
            images: [
              {
                src: "t1-black.png",
                alt: "Layout 1",
              },
              {
                src: "t2-black.png",
                alt: "Layout 2",
              },
              {
                src: "t3-black.png",
                alt: "Layout 3",
              },
              {
                src: "t4-black.png",
                alt: "Layout 4",
              },
              {
                src: "t5-black.png",
                alt: "Layout 5",
              },
              {
                src: "t6-black.png",
                alt: "Layout 6",
              },
            ],
          },
        },
        {
          id: 2,
          colorId: 2,
          details: {
            images: [
              {
                src: "t1-blue.png",
                alt: "Layout 1",
              },
              {
                src: "t2-blue.png",
                alt: "Layout 2",
              },
              {
                src: "t3-blue.png",
                alt: "Layout 3",
              },
              {
                src: "t4-blue.png",
                alt: "Layout 4",
              },
              {
                src: "t5-blue.png",
                alt: "Layout 5",
              },
              {
                src: "t6-blue.png",
                alt: "Layout 6",
              },
            ],
          },
        },
        {
          id: 3,
          colorId: 3,
          details: {
            images: [
              {
                src: "t1-salmon.png",
                alt: "Layout 1",
              },
              {
                src: "t2-salmon.png",
                alt: "Layout 2",
              },
              {
                src: "t3-salmon.png",
                alt: "Layout 3",
              },
              {
                src: "t4-salmon.png",
                alt: "Layout 4",
              },
              {
                src: "t5-salmon.png",
                alt: "Layout 5",
              },
              {
                src: "t6-salmon.png",
                alt: "Layout 6",
              },
            ],
          },
        },
        {
          id: 4,
          colorId: 4,
          details: {
            images: [
              {
                src: "t1-teal.png",
                alt: "Layout 1",
              },
              {
                src: "t2-teal.png",
                alt: "Layout 2",
              },
              {
                src: "t3-teal.png",
                alt: "Layout 3",
              },
              {
                src: "t4-teal.png",
                alt: "Layout 4",
              },
              {
                src: "t5-teal.png",
                alt: "Layout 5",
              },
              {
                src: "t6-teal.png",
                alt: "Layout 6",
              },
            ],
          },
        },
        {
          id: 5,
          colorId: 5,
          details: {
            images: [
              {
                src: "t1-gray.png",
                alt: "Layout 1",
              },
              {
                src: "t2-gray.png",
                alt: "Layout 2",
              },
              {
                src: "t3-gray.png",
                alt: "Layout 3",
              },
              {
                src: "t4-gray.png",
                alt: "Layout 4",
              },
              {
                src: "t5-gray.png",
                alt: "Layout 5",
              },
              {
                src: "t6-gray.png",
                alt: "Layout 6",
              },
            ],
          },
        },
        {
          id: 6,
          colorId: 6,
          details: {
            images: [
              {
                src: "t1-purple.png",
                alt: "Layout 1",
              },
              {
                src: "t2-purple.png",
                alt: "Layout 2",
              },
              {
                src: "t3-purple.png",
                alt: "Layout 3",
              },
              {
                src: "t4-purple.png",
                alt: "Layout 4",
              },
              {
                src: "t5-purple.png",
                alt: "Layout 5",
              },
              {
                src: "t6-purple.png",
                alt: "Layout 6",
              },
            ],
          },
        },
      ],
      hovered: false,
      hoveredClass: "hovered-preview",
      isChanged: false,
      invalid: true,
    };
  },
  computed: {
    group() {
      const group = this.themeGroup.filter(
        (x) => x.colorId == this.selectedColorId
      )[0].details;

      return group;
    },
    defaultGroup() {
      //Just get the first images group and grayscale all
      const group = this.themeGroup.filter((x) => x.colorId == 1)[0].details;

      return group;
    },
  },
  watch: {
    candidateModel() {
      if (
        this.candidateModel.profileColor > 0 &&
        this.candidateModel.profileTheme > 0
      )
        this.invalid = false;
      else this.invalid = true;
    },
  },
  created() {
    this.selectedThemeId = this.candidateModel.profileTheme;
    this.selectedColorId = this.candidateModel.profileColor;
    this.selectedTheme.colorId = this.candidateModel.profileColor;
    this.selectedTheme.themeId = this.candidateModel.profileTheme;
  },
  mounted() {
    if (
      this.candidateModel.profileColor > 0 &&
      this.candidateModel.profileTheme > 0
    ) {
      const activeTheme = document.querySelector(
        `#preview-t${this.candidateModel.profileTheme}-${this.candidateModel.profileColor}`
      );

      if (activeTheme) activeTheme.classList.add("active-theme");
    }
  },
  methods: {
    selectColor(colorId) {
      this.selectedColorId = colorId;
      this.selectedTheme.colorId = this.candidateModel.profileColor;
    },
    activeTheme: (candidate, theme) => {
      return candidate?.profileColor === theme.colorId &&
        candidate?.profileTheme === theme.themeId
        ? `active-theme`
        : null;
    },
    customClass(e, state) {
      let el = document.querySelector(`#${e.srcElement.id}`);
      state === 0
        ? el.classList.remove(this.hoveredClass)
        : el.classList.add(this.hoveredClass);
    },
    onTemplateSet(theme) {
      this.isChanged = true;
      this.selectedTheme = theme;
      let selected = document.querySelector(
        `#preview-t${theme.themeId}-${theme.colorId}`
      );
      if (document.querySelector(".active-theme"))
        document
          .querySelector(".active-theme")
          .classList.remove("active-theme");

      selected.classList.add("active-theme");
    },
    onSelectTemplate(e, template) {
      this.isChanged = true;
      this.invalid = false;
      this.selectedTheme = {
        themeId: template.i + 1,
        colorId: this.selectedColorId,
        src: template.image.src,
        alt: template.image.alt,
      };

      if (document.querySelector(".active-theme"))
        document
          .querySelector(".active-theme")
          .classList.remove("active-theme");

      let el = document.querySelector(`#${e.srcElement.id}`);
      el.classList.add("active-theme");
    },
    onTemplatePreview(template) {
      this.selectedTheme = {
        themeId: template.i + 1,
        colorId: this.selectedColorId,
        src: template.image.src,
        alt: template.image.alt,
      };

      this.showThemePreview = true;
    },
    onSkip() {
      this.$router.push("contact-email");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.candidateModel.userId,
        themeId: this.selectedTheme.themeId,
        colorId: this.selectedColorId,
      };

      this.isSubmitting = true;
      this.saving = true;

      this.candidateService
        .SaveThemeAsync(payload)
        .then((res) => {
          setTimeout(() => {
            this.$store.commit("candidates/SET_CANDIDATE", res.data);
            this.saving = false;
            if (fromBreadcrumb != true) {
              this.$router.push("contact-email");
            }
          }, 500);
        })
        .catch((e) => {
          this.saving = false;
          this.errorMessage = e;
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged && !this.isSubmitting) {
      const answer = window.confirm(
        "You have unsaved changes. Do you really want to leave?"
      );
      if (answer) {
        this.isSubmitting = false;
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

  .admin-wizard {
    .card-title {
      margin-bottom: 30px;
    }

    .color-pills {
      button {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100px;
        border: none;
        color: transparent;

        &:focus,
        &:active {
          outline: none !important;
          box-shadow: none !important;
          border: 4px solid #007bff;
        }
      }

      .color-profile-1 {
        background: linear-gradient(-90deg, #151815 50%, #c64a39 50%);
      }

      .color-profile-2 {
        background: linear-gradient(-90deg, #222244 50%, #2e99cc 50%);
      }

      .color-profile-3 {
        background: linear-gradient(-90deg, #f76b6b 50%, #f6e9a1 50%);
      }

      .color-profile-4 {
        background: linear-gradient(-90deg, #182527 50%, #63ceb6 50%);
      }

      .color-profile-5 {
        background: linear-gradient(-90deg, #000000 50%, #b1b1b1 50%);
      }

      .color-profile-6 {
        background: linear-gradient(-90deg, #6667ab 50%, #b3b3d5 50%);
      }
    }

    .theme-wrapper {
      margin-bottom: 40px;
      padding-left: 10px;
      padding-right: 10px;

      .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .theme-label {
      font-family: $montserrat;
      font-size: 20px;
      font-weight: 600;
    }

    .theme-thumb {
      margin-top: 10px;
      padding: 5px;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: 0 0;
      cursor: pointer;
    }

    .preview-btn {
      margin-top: 15px;

      svg {
        margin-left: 5px;
      }
    }
  }

.active-theme {
  background-color: $blue !important;
}

.hovered-preview,
.hovered-selected {
  background-color: $black;
}

.default-group img {
  filter: grayscale(100%);
}
</style>
